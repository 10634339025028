/* eslint-disable import/order */
const React = require('react');
const PropTypes = require('prop-types');
const serialize = require('serialize-javascript');
const { useEffect } = require('react');

const noop = require('lodash/noop');
const { Style } = require('nordic/style');
const { Script } = require('nordic/script');
const { GTM } = require('nordic/gtm');
const { Hotjar } = require('nordic/hotjar');
const { MelidataTrack } = require('nordic/melidata/melidata-track');

const { TrackingProvider } = require('@pog-ui-landings-library/framework');

const { ddRumScript } = require('../../utils/scripts');
const {
  DEPRECATED_SECTIONS,
  SELLERS_SECTIONS,
} = require('../../utils/constants');
const {
  hasRemoteModules,
  getSectionName,
  getUniqueSectionTypes,
} = require('./utils/section-helpers');
const { getTrackHandlers } = require('./utils/tracking-helpers');
const LandingHead = require('./components/LandingHead');
const SectionStyles = require('./components/SectionStyles');
const SellersComponent = require('./components/SellersComponent');
const CalmComponent = require('./components/CalmComponent');

const SellersView = ({
  experiments,
  imagesPrefix,
  isMobile,
  isWebView,
  landingConfig: {
    groupId,
    variantId,
    landingVersionId: versionId,
    variantConfigurationId,
    variantName,
    head,
    productName,
    space,
    tracking: { gtm, melidata, hotjar },
    theme,
    custom: { ddrumConfig = null },
  },
  locale,
  device,
  mainStyles,
  scope,
  sections,
  initCallback,
  landingStatus,
  isPreview,
  recaptchaSiteKey,
  coreValues: {
    userId,
    coupon = null,
    channel = null,
    subChannel = null,
    siteId = null,
    userAgent = null,
  },
}) => {
  const filteredSections = sections.filter(
    ({ type }) => !DEPRECATED_SECTIONS.includes(type.toLowerCase()),
  );

  const uniqueSectionsTypes = getUniqueSectionTypes(filteredSections);
  const hasRemoteModuleSections = hasRemoteModules(filteredSections);

  // Set handlers the components will call to report tracks
  const trackHandle = getTrackHandlers(melidata, gtm);

  const handleScroll = () => {
    const targetId = window?.location.hash.slice(1);

    if (targetId) {
      try {
        const position = document.querySelector(`#${targetId}`)?.offsetTop;

        if (position) {
          window.scrollTo(0, position);
        }
      } catch (err) {
        // Fragment and not a target section
      }
    }
  };

  useEffect(() => {
    if (document.readyState === 'complete') {
      handleScroll();
    } else {
      window.addEventListener('load', handleScroll);
    }

    // Datadog RUM script for browser, options in global settings > custom > ddrum
    if (ddrumConfig) {
      ddRumScript(ddrumConfig, {
        userId,
        coupon,
        channel,
        subChannel,
        groupId,
        variantId,
        versionId,
        variantConfigurationId,
        variantName,
        experiments,
        isWebView,
        isMobile,
        tracking: melidata,
        siteId,
        userAgent,
      });
    }

    return () => window.removeEventListener('load', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main
      id="s-landing"
      className="s-landing s-landing--sellers"
      style={mainStyles}
      data-group-id={groupId}
      data-variant-id={variantId}
      data-version-id={versionId}
      data-variant-configuration-id={variantConfigurationId}
      data-variant-name={variantName}
      data-product-name={productName}
      ref={initCallback}
    >
      <Style
        href={isMobile ? 'landingMobile.css' : 'landingDesktop.css'}
        critical
      />

      <SectionStyles
        types={uniqueSectionsTypes}
        theme={theme}
        isMobile={isMobile}
      />

      <LandingHead head={head} scope={scope} />

      <div className="s-sections">
        <TrackingProvider trackHandler={trackHandle}>
          {filteredSections?.map((section) => {
            const { id, config, type } = section;
            const { anchor, id: configId, form } = config || {};
            const key = `${type}__${id}`;

            // FIXME: Homogenize alias in data to follow sections' schemas (They declare id as attribute).
            const sectionID = configId || anchor || type;

            if (form?.recaptcha) {
              const recaptchaConfig = form.recaptcha;
              const recaptcha =
                typeof recaptchaConfig === 'object' ? recaptchaConfig : {};

              config.form = {
                ...(form || {}),
                recaptcha: {
                  ...recaptcha,
                  key: recaptcha.key || recaptchaSiteKey,
                },
              };
            }

            const Component = SELLERS_SECTIONS.includes(
              getSectionName(type.toLowerCase()),
            )
              ? SellersComponent
              : CalmComponent;

            return (
              <Component
                type={getSectionName(type.toLowerCase())}
                id={sectionID}
                key={key}
                config={config}
                isMobile={isMobile}
                isWebView={isWebView}
                theme={theme}
                moduleKey={key}
              />
            );
          })}
        </TrackingProvider>
      </div>

      {gtm && <GTM {...gtm} />}

      {melidata && <MelidataTrack {...melidata} MelidataTrack={experiments} />}

      {/* Options are inferred from frontend-config.
        See https://github.com/mercadolibre/fury_frontend-metrics/tree/master/packages/hotjar#options */}
      {hotjar && !hasRemoteModuleSections && <Hotjar id={hotjar.id} />}

      <Script priority={1}>
        {`window.__PRELOADED_STATE__ = ${serialize(
          {
            experiments,
            imagesPrefix,
            isMobile,
            isWebView,
            landingConfig: {
              groupId,
              variantId,
              versionId,
              variantConfigurationId,
              variantName,
              head,
              productName,
              space,
              tracking: {
                gtm,
                melidata,
                hotjar,
              },
              theme,
              custom: { ddrumConfig },
            },
            locale,
            device,
            scope,
            sections: filteredSections,
            trackHandle,
            mainStyles,
            landingStatus,
            isPreview,
            recaptchaSiteKey,
            coreValues: {
              userId,
              coupon,
              channel,
              subChannel,
              siteId,
              userAgent,
            },
          },
          { isJSON: true },
        )};`}
      </Script>

      <Script priority={2} src={['vendor.js']} />
    </main>
  );
};

SellersView.propTypes = {
  experiments: PropTypes.shape({}),
  imagesPrefix: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  isWebView: PropTypes.bool,
  landingConfig: PropTypes.shape({
    groupId: PropTypes.string,
    variantId: PropTypes.string,
    landingVersionId: PropTypes.string,
    variantConfigurationId: PropTypes.string,
    variantName: PropTypes.string,
    head: PropTypes.shape({ title: PropTypes.string }),
    productName: PropTypes.string,
    space: PropTypes.string,
    theme: PropTypes.string,
    tracking: PropTypes.shape({
      gtm: PropTypes.shape({}),
      melidata: PropTypes.shape({}),
      hotjar: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
    custom: PropTypes.shape({
      ddrumConfig: PropTypes.shape({}),
    }),
  }),
  locale: PropTypes.string,
  device: PropTypes.shape({
    type: PropTypes.string,
    webView: PropTypes.bool,
  }),
  mainStyles: PropTypes.shape({}),
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  scope: PropTypes.string,
  initCallback: PropTypes.func,
  landingStatus: PropTypes.shape({}),
  isPreview: PropTypes.bool,
  recaptchaSiteKey: PropTypes.string,
  coreValues: PropTypes.shape({
    userId: PropTypes.string,
    coupon: PropTypes.string,
    channel: PropTypes.string,
    subChannel: PropTypes.string,
    siteId: PropTypes.string,
    userAgent: PropTypes.string,
  }),
};

SellersView.defaultProps = {
  sections: [],
  landingConfig: {},
  experiments: {},
  imagesPrefix: '/',
  mainStyles: {},
  scope: '',
  isWebView: false,
  initCallback: noop,
  coreValues: {},
};

module.exports = SellersView;
