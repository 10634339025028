const React = require('react');

const { loadableReady } = require('nordic/lazy');
const { ImageProvider } = require('nordic/image');
const { hydrateRoot } = require('nordic/hydrate');
const { AndesProvider } = require('@andes/context');

const SellersView = require('../../pages/landing/sellersView');
const hotjar = require('../scripts/hotjar');
const eventTracker = require('../scripts/event-tracking');

/**
 * Styles
 */
require('../../pages/landing/styles.desktop.scss');

const props = window.__PRELOADED_STATE__;

const initCallback = () => {
  const {
    isPreview,
    landingStatus,
    landingConfig: {
      tracking: { melidata },
    },
  } = window.__PRELOADED_STATE__;

  eventTracker.initEventTracking(melidata);
  hotjar.initEventTracking();

  const inIframe = window.self !== window.top;

  if (isPreview && inIframe) {
    window.parent.postMessage(landingStatus, landingStatus.iframeOrigin);
  }
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
loadableReady(() => {
  hydrateRoot(
    document.getElementById('root-app'),
    <AndesProvider locale={props?.locale} device={props?.device}>
      <ImageProvider prefix={props?.imagesPrefix}>
        <SellersView {...props} initCallback={initCallback} />
      </ImageProvider>
    </AndesProvider>,
  );
});
